<template>
	<form ref="contactform" method="post" class="contact-form" @submit.prevent="submit()">
		<span v-if="state.error" class="error">
			{{ $t(`form${capitalize(state.error)}`) }}
		</span>
		<div class="form-column">
			<div v-for="field in form" :key="field.name">
				<div v-if="field.name == 'eventDate'">
					<label v-if="field.required"> {{ $t(`form${capitalize(field.name)}`) }} * </label>
					<label v-else> {{ $t(`form${capitalize(field.name)}`) }} </label>
					<client-only>
						<date-picker
							v-model:value="state.eventDate"
							value-type="format"
							format="DD-MM-YYYY"
							class=""
							:lang="datepickerLang"
						>
							<template #icon-calendar> &nbsp; </template>
						</date-picker>
					</client-only>
				</div>
				<div v-else-if="field.edittype == 'radiogroup'" class="formgroup-container">
					<div class="formgroup-heading">
						<strong>{{ $t(`form${capitalize(field.name)}`) }} *</strong>
					</div>
					<div class="radiobuttons">
						<div v-for="(value, key) in field.options" :key="key">
							<label>
								<input v-model="field.value" type="radio" :name="field.name" :value="key" />
								{{ $t(`form${capitalize(key)}`) }}
								<span v-if="field.required"> *</span>
							</label>
						</div>
						<div v-if="!field.required">
							<label>
								<input type="radio" :name="field.name" value="" />{{ $t('formNoPreference') }}
							</label>
						</div>
					</div>
				</div>
				<div v-else-if="field.edittype == 'list'" class="select">
					<select :id="field.name" v-model="field.value" :name="field.name">
						<option v-if="!field.required" value="null" selected>
							{{ $t(`form${capitalize(field.name)}`) }}
						</option>
						<option v-for="(value, key) in field.options" :key="key" :value="value">
							{{ value }}
						</option>
					</select>
				</div>
				<div v-else-if="field.edittype == 'checkbox'" />
				<div v-else-if="field.edittype == 'textarea'" />
				<div v-else-if="field.name == 'eventType'" />
				<div v-else>
					<label v-if="field.required"> {{ $t(`form${capitalize(field.name)}`) }} * </label>
					<label v-else> {{ $t(`form${capitalize(field.name)}`) }} </label>
					<input
						:key="field.name"
						v-model="field.value"
						:name="field.name"
						:required="field.required"
						:type="state.inputTypes[field.name] || 'text'"
					/>
				</div>
			</div>
		</div>
		<div class="form-column">
			<div v-for="field in form" :key="field.name">
				<div v-if="field.edittype == 'checkbox'" class="formgroup-container">
					<div class="checkboxes">
						<label :for="field.name">
							<input
								v-model="field.value"
								:name="field.name"
								type="checkbox"
								:checked="!!field.value"
								:placeholder="$t(`form${capitalize(field.name)}`)"
								:required="field.required"
							/>{{ $t(`form${capitalize(field.name)}`) }}
							<span v-if="field.required"> *</span>
						</label>
					</div>
				</div>

				<div v-if="field.name == 'eventType'" class="formgroup-container">
					<div class="formgroup-heading">
						<strong>{{ $t(`form${capitalize(field.name)}`) }} *</strong>
					</div>
					<div v-for="(key, value) in field.options" :key="key" class="checkboxes">
						<label>
							<input
								:id="field.name"
								v-model="state.eventTypes"
								type="checkbox"
								:name="field.name"
								:value="$t(`form${capitalize(value)}`)"
							/>
							{{ $t(`form${capitalize(value)}`) }}
						</label>
					</div>
					<div class="checkboxes block">
						<label>
							<input
								v-model="state.eventTypeOtherEnabled"
								name="otherSpecify"
								type="checkbox"
								class="toggle-extra-option"
							/>{{ $t('formOtherSpecify') }}
						</label>
						<input
							v-model="state.eventTypeOther"
							type="text"
							class="extra-option"
							:disabled="!state.eventTypeOtherEnabled"
						/>
					</div>
				</div>

				<div v-else-if="field.edittype == 'textarea'">
					<label>
						{{ $t(`form${capitalize(field.name)}`) }}
					</label>
					<textarea :key="field.name" v-model="field.value" :name="field.name" :required="field.required" />
				</div>
				<div v-else />
			</div>
			<input type="submit" class="button button-cta action" :value="$t('formSubmit')" />
		</div>
	</form>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();
const { $installRecaptcha, $executeRecaptcha } = useNuxtApp();
const { t } = useI18n();

const contactform = ref(null);

const props = defineProps({
	form: { type: Array, default: () => [] },
	name: { type: String, default: '' },
});

const state = useState('state', () => ({
	eventDate: '',
	eventTypes: [''],
	eventTypeOtherEnabled: false,
	eventTypeOther: '',
	inputTypes: { email: 'email', phone: 'tel' },
	error: '',
}));

const datepickerLang = computed(() => datePickerTranslations[locale.value] ?? datePickerTranslations.en);

const submit = async () => {
	const data = props.form.reduce((result, item) => ({ ...result, [item.name]: item.value ?? '' }), {});

	if (state.value.eventDate) {
		data.eventDate = state.value.eventDate;
	}

	if (state.value.eventTypeOther) {
		state.value.eventTypes.push(`${t('formOtherSpecify')} ${state.value.eventTypeOther}`);
	}

	if (state.value.eventTypes.length) {
		data.eventType = state.value.eventTypes.join(', ');
	}

	data.language = locale.value;

	if (window.recaptcha) {
		data['g-recaptcha-response'] = await $executeRecaptcha();
	}

	const { data: result } = await useWebsiteFetch(props.name, {
		method: 'POST',
		body: JSON.stringify(data),
		query: { language: locale.value },
	});

	if (result.value.status === 'error') {
		state.value.error = result.error;
	} else if (result.value.redirect) {
		contactform.value.reset();
		navigateTo(result.value.redirect);
	}
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

onMounted(async () => {
	await $installRecaptcha();
});
</script>

<style lang="scss" scoped>
.mx-datepicker {
	width: 100%;
}

.form-wrapper {
	width: 100%;
}

form {
	margin: 40px 0 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;

	.form-column {
		width: 48%;
	}

	div {
		display: flex;
		flex-flow: column wrap;
		width: 100%;
		text-align: left;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&.vacancies-form {
		flex-flow: row wrap;

		> div {
			width: 48%;
			margin: 1% 2% 1% 0;
		}
	}

	.error {
		display: block;
		margin: 0 0 10px;
	}

	:deep(input:not([type='checkbox'], [type='radio'], [type='submit'])),
	select,
	textarea {
		color: #d9d4c6;
		width: 100%;
		max-width: 500px;
		padding: 15px 20px;
		border: 1px solid #d9d4c6;
		margin: 0 0 20px;
		background: none;
		font-size: 13px;
		font-family: var(--body-font-family);
	}

	select {
		background: url('~/assets/images/icon-form-dropdown.png') no-repeat;
		background-position: right 15px top 50%;
		appearance: none;
	}

	input[type='submit'] {
		width: auto;
	}

	textarea {
		min-height: 140px;
		max-width: 100%;
	}

	label {
		color: #d9d4c6;
		margin: 0 0 5px;
		display: inline-block;
	}

	:deep(.mx-input) {
		height: auto;
		font-size: unset;
		color: unset;

		&:focus,
		&:hover {
			border-color: #d9d4c6;
		}

		&:focus {
			outline: revert;
		}
	}

	.button {
		appearance: none;
		cursor: pointer;
		outline: none;
		border: none;
		width: 175px !important;
		height: 61px !important;
	}

	.formgroup-container {
		margin: 0 0 25px;

		.formgroup-heading {
			margin-bottom: 15px;
		}

		.checkboxes {
			display: flex;
			margin: 5px 0 10px;

			&.block {
				display: block;
			}

			.extra-option {
				margin: 15px 0 0;
			}

			label {
				width: auto;
				padding: 0;
				font-size: 0.9em;
				margin: 0 5px 0 0;
				display: flex;
				gap: 10px;
				align-items: center;
				color: #d9d4c6;

				input {
					width: auto;
					padding: 0;
					appearance: checkbox;
					margin: -2px 6px 0 0;
				}
			}
		}

		.radiobuttons {
			display: flex;
			flex-flow: row wrap;
			margin: 5px 0 10px;

			> div {
				width: 100%;
				margin: 0 0 10px;
			}

			label,
			input {
				width: auto;
				padding: 0;
				font-size: 0.9em;
			}

			label {
				margin: 0 5px 0 0;
				display: flex;
				gap: 10px;

				input {
					width: auto;
					appearance: checkbox;
					margin: -2px 6px 0 0;
					padding: 0;
				}
			}
		}
	}
}

@media (max-width: 680px) {
	form {
		.form-column {
			width: 100%;
		}

		:deep(input:not([type='checkbox'], [type='radio'], [type='submit'])),
		select,
		textarea {
			padding: 13px 18px;
			margin: 0 0 15px;
			font-size: 12px;
		}
	}
}
</style>
